import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-addbrand',
  templateUrl: './addbrand.component.html',
  styleUrls: ['./addbrand.component.css']
})
export class AddbrandComponent implements OnInit {

  //Mode Values
  isaddmode:boolean=false;
  iseditmode:boolean=false;
  isdeletemode:boolean=false;
  isviewmode:boolean=false;

  //Save Validation
  submitted:boolean;
  successfully:boolean;

  //set image
  image;
  strImage: any;

  //Edit Get Values
  brandColl:any=[];
  brandid:number=0
  picurl:string="";
  picname:string="";

  pageColl:any=[];

  brandform=new FormGroup({
    brandname:new FormControl('',Validators.required),         
    brandid:new FormControl(0), 
    image:new FormControl(null),        
    inactive:new FormControl(false),    
    isdisplay:new FormControl(false),
    pageid:new FormControl(null),      
    sequenceno:new FormControl(0),  
  });

  isloading:boolean=false;
  isprivilage:boolean=false;  
showProgress:boolean=false;
  formname:String="Brand";

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
     public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
       this.route.queryParams.subscribe(params => {
         if(params.id!==undefined){
          this.brandid = params.id
        }  
       });

       this.fnServiceChanges();
}


fnServiceChanges(){
  this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

 

ngOnInit() {
  try{
    if(this.loginService.fnCheckUser()){ 
      if(this.fnCheckUserPririvilage()){       
        this.isloading=true;
        this.fnOnInitForm();
      }
      else{
        this.fnUserFailedForm();
      }
    }
    else{
      this.loginService.fnGetUserRoleList();
    }
            
  }catch(ex){
    this.fnShowErrorMsg(ex);
  }
}

fnOnInitForm(){
     try{
         this.isaddmode=true;
         this.brandform.reset();
         this.fnInitializeBrandFormGroup(); 

         if(this.brandid>0){
           this.fngetBrand();
         }
         this.fngetPage();
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
}

fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.brandid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}


fnInitializeBrandFormGroup(){
     this.brandform.setValue({
      brandname:'',
      brandid:0,
       image:null,
       inactive:false,  
       isdisplay:false,
       sequenceno:0,    
       pageid:null,
     })
}

//Get 
fngetBrand() {        
       var data = {
         'brandid':this.brandid,            
       }
       this.appService.getBrand(data)
       .subscribe(
         (res)=>{                  
           this.brandColl=res.json().result;
           var editdata = {
             'brandid':this.brandColl[0].brandid,
             'brandname':this.brandColl[0].brandname, 
             'inactive':this.brandColl[0].inactive,
             'picurl':this.brandColl[0].picurl,            
             'picname':this.brandColl[0].picname,
             'isdisplay':this.brandColl[0].isdisplay,          
             'sequenceno':this.brandColl[0].sequenceno,  
             'pageid':this.brandColl[0].pageid,                    
           }
           this.fnEditMode(editdata);
       },
       (err)=>{          
         this.toastr.error('Error', err);
       })
}



  
fngetPage() {   
  var data = {
        'pageid': 0,            
  }
  this.appService.getPageByListView(data)
      .subscribe(
        (res)=>{                  
          if(res.json().status==200){                          
            this.pageColl=res.json().result;          
          }
          else if(res.json().status==400){                          
            this.toastr.error('Error', res.json().err_field);
          }
         
         
      },
        (err)=>{          
          this.toastr.error('Error', err);
        })
}



//Save Btn Click
fnSaveBtnClick(){
     try{
       this.submitted=true;
       this.successfully=false;
       //Validate
       if(this.brandform.valid){
           //Check
           var data = {
                 'brandid': this.brandid,       
                 'brandname':this.brandform.get('brandname').value,            
                 'inactive': this.brandform.get('inactive').value,   
                 'createdby': this.loginService.fnGetModUserId(),    
                 'modifiedby':this.loginService.fnGetModUserId(),    
                 'image': this.strImage,
                 'picurl': this.picurl,
                 'picname': this.picname,
                 'isdisplay': this.brandform.get('isdisplay').value,  
                 'sequenceno': this.brandform.get('sequenceno').value,  
                 'pageid':this.brandform.get('pageid').value,            
           }

           this.appService.fnCheckBrand(data).subscribe(
             (res)=>{
                 var _checkresult=res.json().result[0].checkfield;
               
                 if(_checkresult==="done"){
                       if (this.brandform.get('brandid').value>0){
                         //Update Department
                         this.fnSetBrand(data);
                       }
                       else{
                         //Insert Department
                         this.fnInsertBrand(data);
                       }
                 }
                 else{        
                       if(_checkresult==="name"){
                         this.brandform.controls['brandname'].setErrors({'notfound': true});
                       }
                 }
           },
           (err)=>{
               this.toastr.error('Error', err);           
           });
        }
     }catch(ex){
       this.fnShowErrorMsg(ex);
     }
}

//Insert
fnInsertBrand(data){    
   
     this.appService.fnInsertBrand(data).subscribe(
       (res)=>{
       if(res.json().result!=""){
         this.toastr.success('', "The new brand has been added successfully.Insert Successfully");

         this.fnInsertActivityLog('INSERT',0,data.brandname);

         this.fnCloseBrand();
       }},
       (err)=>{
         this.toastr.error('Error', err);
       });

}

//Set
fnSetBrand(data){    

 this.appService.fnSetBrand(data).subscribe(
   (res)=>{
   if(res.json().result!=""){
       this.toastr.success('', "The brand has been updated successfully.");

       this.fnInsertActivityLog('EDIT',this.brandid,data.brandname); 

       this.fnCloseBrand();        
   }},
   (err)=>{
     this.toastr.error('Error', err);
   });

}

//Close
fnCloseBrand(){
 try{
    this.router.navigate(['/Brand']);
 }catch(ex){
     this.fnShowErrorMsg(ex);
 }
}

private fnEditMode(deptBO){
   this.brandform.setValue({
    brandname:deptBO.brandname,
    brandid:deptBO.brandid,
     inactive:deptBO.inactive, 
     image:null,  
     isdisplay:deptBO.isdisplay, 
     sequenceno:deptBO.sequenceno,
     pageid:deptBO.pageid    
   });
   this.picurl =deptBO.picurl;
   this.picname =deptBO.picname;

 this.isaddmode=false;
 this.iseditmode=true;
 this.isdeletemode=false;
 this.isviewmode=false;
}

//Image Import
changeListener($event): void {
try{
   this.readThis($event.target);
 }catch(ex){
   this.fnShowErrorMsg(ex);
 }
}

readThis(inputValue: any): void {

 var file: File = inputValue.files[0];
 var myReader: FileReader = new FileReader();

 myReader.onloadend = (e) => {
     this.image = myReader.result;
     this.strImage = this.image.split(',')[1];
 }
 myReader.readAsDataURL(file);
}

removeimage(){
 this.image =null;
 this.strImage =null;
 this.picurl="";
}

fnOpenFileBrowser(event:any){

  event.preventDefault();

  let element:HTMLElement=document.getElementById('image') as HTMLElement;
  element.click();

}


    
fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}



private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


private  fnShowErrorMsg(ex){
 this.toastr.warning('Internal Error', ex);       
}


}
