import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import {INgxSelectOption} from 'ngx-select-ex';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-addcategory',
  templateUrl: './addcategory.component.html',
  styleUrls: ['./addcategory.component.css']
})
export class AddcategoryComponent implements OnInit {
 //Mode Values
 isaddmode:boolean=false;
 iseditmode:boolean=false;
 isdeletemode:boolean=false;
 isviewmode:boolean=false;

 //Save Validation
 submitted:boolean;
 successfully:boolean;

 //set image
 image;
 strImage: any;

 //Edit Get Values
 deptColl:any=[];
 categoryColl:any=[]; 
 categoryid:number=0
 picurl:string="";
 picname:string="";

 categoryform=new FormGroup({
  categoryname:new FormControl('',Validators.required),         
  categoryid:new FormControl(0),
  deptid:new FormControl(null,Validators.required), 
  image:new FormControl(null),        
  inactive:new FormControl(false),   
  isfooterdisplay:new FormControl(false),    
 });

 list = [];

 isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="Category";


constructor(private appService: DhukanDataService,private route: ActivatedRoute,
    public router: Router,private toastr: ToastrService,private loginService:LoginDataService) { 
      this.route.queryParams.subscribe(params => {
        if(params.id!==undefined){
          this.categoryid = params.id
        }  
      });


 this.fnServiceChanges();
}



fnServiceChanges(){
  this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}



ngOnInit() {
  try{
    if(this.loginService.fnCheckUser()){ 
      if(this.fnCheckUserPririvilage()){       
        this.isloading=true;
        this.fnOnInitForm();
      }
      else{
        this.fnUserFailedForm();
      }
    }
    else{
      this.loginService.fnGetUserRoleList();
    }
            
  }catch(ex){
    this.fnShowErrorMsg(ex);
  }
}

fnOnInitForm(){

    try{
        this.isaddmode=true;
        this.categoryform.reset();
        this.fnInitializeCategoryFormGroup(); 

        if(this.categoryid>0){
          this.fngetCategory();
        }

        this.fngetDepartment();
        
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}


fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.categoryid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}


fnInitializeCategoryFormGroup(){
    this.categoryform.setValue({
      categoryname:'',
      categoryid:0,
      deptid:null,
      image:null,
      inactive:false,  
      isfooterdisplay:false,    
    })
}

//Get 
fngetDepartment() {        
  var data = {
    'deptid':0,            
  }
  this.appService.getDepartment(data)
  .subscribe(
    (res)=>{                  
      this.deptColl=res.json().result;     
    },
  (err)=>{          
    this.toastr.error('Error', err);
  })
}

//Get 
fngetCategory() {        
      var data = {
        'categoryid':this.categoryid,            
      }
      this.appService.getCategory(data)
      .subscribe(
        (res)=>{                  
          this.categoryColl=res.json().result;
          var editdata = {
            'categoryid':this.categoryColl[0].categoryid,
            'categoryname':this.categoryColl[0].categoryname, 
            'deptid':this.categoryColl[0].deptid,
            'inactive':this.categoryColl[0].inactive,
            'picurl':this.categoryColl[0].picurl,            
            'picname':this.categoryColl[0].picname,  
            'isfooterdisplay':this.categoryColl[0].isfooterdisplay,          
          }
          this.fnEditMode(editdata);
      },
      (err)=>{          
        this.toastr.error('Error', err);
      })
}

//Save Btn Click
fnSaveBtnClick(){
    try{
      this.submitted=true;
      this.successfully=false;
      //Validate
      if(this.categoryform.valid){
          //Check
          var data = {
                'categoryid': this.categoryid,       
                'categoryname':this.categoryform.get('categoryname').value, 
                'deptid':this.categoryform.get('deptid').value,           
                'inactive': this.categoryform.get('inactive').value,   
                'createdby': this.loginService.fnGetModUserId(),    
                'modifiedby':this.loginService.fnGetModUserId(),    
                'isfooterdisplay':this.categoryform.get('isfooterdisplay').value, 
                'image': this.strImage,
                'picurl': this.picurl,
                'picname': this.picname,
          }
          this.appService.fnCheckCategory(data).subscribe(
            (res)=>{
                var _checkresult=res.json().result[0].checkfield;
              
                if(_checkresult==="done"){
                      if (this.categoryform.get('categoryid').value>0){
                        //Update Department
                        this.fnSetCategory(data);
                      }
                      else{
                        //Insert Department
                        this.fnInsertCategory(data);
                      }
                }
                else{        
                      if(_checkresult==="name"){
                        this.categoryform.controls['categoryname'].setErrors({'notfound': true});
                      }
                }
          },
          (err)=>{
              this.toastr.error('Error', err);           
          });
       }
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}

//Insert
fnInsertCategory(data){    
  
    this.appService.fnInsertCategory(data).subscribe(
      (res)=>{
      if(res.json().result!=""){
        this.toastr.success('', "The new category has been added successfully.Insert Successfully");

        this.fnInsertActivityLog('INSERT',0,data.categoryname);

        this.fnCloseCategory();
      }},
      (err)=>{
        this.toastr.error('Error', err);
      });

}

//Set
fnSetCategory(data){    

this.appService.fnSetCategory(data).subscribe(
  (res)=>{
  if(res.json().result!=""){
      this.toastr.success('', "The caetgory has been updated successfully.");

      this.fnInsertActivityLog('EDIT',this.categoryid,data.categoryname);

      this.fnCloseCategory();        
  }},
  (err)=>{
    this.toastr.error('Error', err);
  });

}

//Close
fnCloseCategory(){
try{
   this.router.navigate(['/Category']);
}catch(ex){
    this.fnShowErrorMsg(ex);
}
}

private fnEditMode(deptBO){
  this.categoryform.setValue({
    categoryname:deptBO.categoryname,
    categoryid:deptBO.categoryid,
    deptid:deptBO.deptid,    
    isfooterdisplay:deptBO.isfooterdisplay,
    inactive:deptBO.inactive, 
    image:null,     
  });
  this.picurl =deptBO.picurl;
  this.picname =deptBO.picname;

this.isaddmode=false;
this.iseditmode=true;
this.isdeletemode=false;
this.isviewmode=false;
}

//Image Import
changeListener($event): void {
try{
  this.readThis($event.target);
}catch(ex){
  this.fnShowErrorMsg(ex);
}
}

readThis(inputValue: any): void {

var file: File = inputValue.files[0];
var myReader: FileReader = new FileReader();

myReader.onloadend = (e) => {
    this.image = myReader.result;
    this.strImage = this.image.split(',')[1];
}
myReader.readAsDataURL(file);
}

removeimage(){
this.image =null;
this.strImage =null;
this.picurl="";
}

fnOpenFileBrowser(event:any){

  event.preventDefault();

  let element:HTMLElement=document.getElementById('image') as HTMLElement;
  element.click();

}

private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}




private  fnShowErrorMsg(ex){
this.toastr.warning('Internal Error', ex);       
}



}
