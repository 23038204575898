
export const AppSettings = {
  //base_url : 'http://localhost:8000/',
  //base_url: 'https://bmapi.bigmart.ae/',
  //base_url : 'http://200.1.50.108:8100/',

  //base_url : 'http://13.127.71.165:5100/',
  //base_url : 'http://54.164.188.85:5100/',
  //base_url: 'http://15.185.158.251:5100/',    
  
  
  //base_url: 'http://200.1.50.102:8100/',  

  base_url: 'https://apibigmartkiosk.bigmart.ae/',
  //base_url: 'http://192.168.0.181:8100/',

  //base_url: 'https://apirenomart.bigmart.ae/',

  //base_url: 'http://200.1.1.65:8100/',  

  firebaseConfig: {
    apiKey: "AIzaSyAmXbqtxuVX8BQlgqIkXzUz0jERiN1Ia8Y",
    authDomain: "renomart-495b5.firebaseapp.com",
    databaseURL:"https://renomart-admin-default-rtdb.firebaseio.com/",
    projectId: "renomart-495b5",
    storageBucket: "renomart-495b5.appspot.com",
    messagingSenderId: "423626768987",
    appId: "1:423626768987:web:10f33b456f017823b3757e",
    measurementId: "G-1BP1VSBFVK"
  },

  insertDepartmentUrl: 'department/insertdepartment',
  setDepartmentUrl: 'department/setdepartment',
  checkDepartmentUrl: 'department/checkdepartment',
  getDepartmentUrl: 'department/getdepartment',
  deleteDepartmentUrl: 'department/deletedepartment',

  insertCategoryUrl: 'category/insertcategory',
  setCategoryUrl: 'category/setcategory',
  checkCategoryUrl: 'category/checkcategory',
  getCategoryUrl: 'category/getcategory',
  deleteCategoryUrl: 'category/deletecategory',




  insertSubCategoryUrl: 'subcategory/insertsubcategory',
  setSubCategoryUrl: 'subcategory/setsubcategory',
  checkSubCategoryUrl: 'subcategory/checksubcategory',
  getSubCategoryUrl: 'subcategory/getsubcategory',
  deleteSubCategoryUrl: 'subcategory/deletesubcategory',

  insertBrandUrl: 'brand/insertbrand',
  setBrandUrl: 'brand/setbrand',
  checkBrandUrl: 'brand/checkbrand',
  getBrandUrl: 'brand/getbrand',
  deleteBrandUrl: 'brand/deletebrand',

  insertUomUrl: 'uom/insertuom',
  setUomUrl: 'uom/setuom',
  checkUomUrl: 'uom/checkuom',
  getUomUrl: 'uom/getuom',
  deleteUomUrl: 'uom/deleteuom',

  insertStatusUrl: 'status/insertstatus',
  setStatusUrl: 'status/setstatus',
  checkStatusUrl: 'status/checkstatus',
  getStatusUrl: 'status/getstatus',
  deleteStatusUrl: 'status/deletestatus',

  insertWarehouseUrl: 'warehouse/insertwarehouse',
  setWarehouseUrl: 'warehouse/setwarehouse',
  checkWarehouseUrl: 'warehouse/checkwarehouse',
  getWarehouseUrl: 'warehouse/getwarehouse',
  deleteWarehouseUrl: 'warehouse/deletewarehouse',

  insertCityUrl: 'city/insertcity',
  setCityUrl: 'city/setcity',
  checkCityUrl: 'city/checkcity',
  getCityUrl: 'city/getcity',
  deleteCityUrl: 'city/deletecity',



  insertSizeUrl: 'size/insertsize',
  setSizeUrl: 'size/setsize',
  checkSizeUrl: 'size/checksize',
  getSizeUrl: 'size/getsize',
  deleteSizeUrl: 'size/deletesize',

  insertColorUrl: 'color/insertcolor',
  setColorUrl: 'color/setcolor',
  checkColorUrl: 'color/checkcolor',
  getColorUrl: 'color/getcolor',
  deleteColorUrl: 'color/deletecolor',

  insertAreaUrl: 'area/insertarea',
  setAreaUrl: 'area/setarea',
  checkAreaUrl: 'area/checkarea',
  getAreaUrl: 'area/getarea',
  deleteAreaUrl: 'area/deletearea',
  getAreaPaymentUrl: 'area/getareapayment',
  getAreaDepartmentUrl: 'area/getareadepartment',

  insertVendorUrl: 'vendor/insertvendor',
  setVendorUrl: 'vendor/setvendor',
  checkVendorUrl: 'vendor/checkvendor',
  getVendorUrl: 'vendor/getvendor',
  deleteVendorUrl: 'vendor/deletevendor',

  insertTaxGroupUrl: 'taxgroup/inserttaxgroup',
  setTaxGroupUrl: 'taxgroup/settaxgroup',
  checkTaxGroupUrl: 'taxgroup/checktaxgroup',
  getTaxGroupUrl: 'taxgroup/gettaxgroup',
  deleteTaxGroupUrl: 'taxgroup/deletetaxgroup',


  insertFoodSymbolUrl: 'foodsymbol/insertfoodsymbol',
  setFoodSymbolUrl: 'foodsymbol/setfoodsymbol',
  checkFoodSymbolUrl: 'foodsymbol/checkfoodsymbol',
  getFoodSymbolUrl: 'foodsymbol/getfoodsymbol',
  deleteFoodSymbolUrl: 'foodsymbol/deletefoodsymbol',


  insertCertificateUrl: 'certificate/insertcertificate',
  setCertificateUrl: 'certificate/setcertificate',
  checkCertificateUrl: 'certificate/checkcertificate',
  getCertificateUrl: 'certificate/getcertificate',
  deleteCertificateUrl: 'certificate/deletecertificate',



  insertBannerTypeUrl: 'bannertype/insertbannertype',
  setBannerTypeUrl: 'bannertype/setbannertype',
  checkBannerTypeUrl: 'bannertype/checkbannertype',
  getBannerTypeUrl: 'bannertype/getbannertype',
  deleteBannerTypeUrl: 'bannertype/deletebannertype',


  insertBannerUrl: 'banner/insertbanner',
  setBannerUrl: 'banner/setbanner',
  checkBannerUrl: 'banner/checkbanner',
  getBannerUrl: 'banner/getbanner',
  deleteBannerUrl: 'banner/deletebanner',

  insertProductUrl: 'product/insertproduct',
  setProductUrl: 'product/setproduct',
  setProductSkuUrl: 'product/setproductsku',
  checkProductUrl: 'product/checkproduct',
  getProductUrl: 'product/getproduct',
  getautocodeproduct: 'product/getautocodeproduct',
  getProductListViewUrl: 'product/getproductbylistview',
  deleteProductUrl: 'product/deleteproduct',
  deleteProductSkuUrl: 'product/deleteproductsku',
  checkProductSkuUrl: 'product/checkproductsku',
  setProductSkuPublishUrl: 'product/setproductskupublish',
  setProductSkuByProductGroupUrl: 'product/setproductskubyproductgroupid',
  getProductRequestListviewUrl: 'common/getproductrequestlistview',
  insertImportProductUrl: 'product/insertimportproduct',
  getProductColorSizeUrl: 'product/getproductcolorsize',
  getProductColorUrl: 'product/getproductcolor',
  getProductSizeUrl: 'product/getproductsize',

  insertProductSectionUrl: 'productsection/insertproductsection',
  setProductSectionUrl: 'productsection/setproductsection',
  getProductSectionUrl: 'productsection/getproductsection',
  deleteProductSectionUrl: 'productsection/deleteproductsection',
  setSectionTypeUrl: 'productsection/setsectiontype',
  getSectionTypeUrl: 'productsection/getsectiontype',


  insertSpecificationUrl: 'specification/insertspecification',
  setSpecificationUrl: 'specification/setspecification',
  checkSpecificationUrl: 'specification/checkspecification',
  getSpecificationUrl: 'specification/getspecification',
  deleteSpecificationUrl: 'specification/deletespecification',


  insertDeliveryTypeUrl: 'deliverytype/insertdeliverytype',
  setDeliveryTypeUrl: 'deliverytype/setdeliverytype',
  checkDeliveryTypeUrl: 'deliverytype/checkdeliverytype',
  getDeliveryTypeUrl: 'deliverytype/getdeliverytype',
  deleteDeliveryTypeUrl: 'deliverytype/deletedeliverytype',

  insertDeliveryBoyUrl: 'deliveryboy/insertdeliveryboy',
  setDeliveryBoyUrl: 'deliveryboy/setdeliveryboy',
  checkDeliveryBoyUrl: 'deliveryboy/checkdeliveryboy',
  getDeliveryBoyUrl: 'deliveryboy/getdeliveryboy',
  deleteDeliveryBoyUrl: 'deliveryboy/deletedeliveryboy',



  insertShipmentTypeUrl: 'shipmenttype/insertshipmenttype',
  setShipmentTypeUrl: 'shipmenttype/setshipmenttype',
  checkShipmentTypeUrl: 'shipmenttype/checkshipmenttype',
  getShipmentTypeUrl: 'shipmenttype/getshipmenttype',
  deleteShipmentTypeUrl: 'shipmenttype/deleteshipmenttype',

  insertDeliverySlotUrl: 'deliveryslot/insertdeliveryslot',
  setDeliverySlotUrl: 'deliveryslot/setdeliveryslot',
  checkDeliverySlotUrl: 'deliveryslot/checkdeliveryslot',
  getDeliverySlotUrl: 'deliveryslot/getdeliveryslot',
  deleteDeliverySlotUrl: 'deliveryslot/deletedeliveryslot',


  insertDeliveryChargesUrl: 'deliverycharges/insertdeliverycharges',
  setDeliveryChargesUrl: 'deliverycharges/setdeliverycharges',
  getDeliveryChargesUrl: 'deliverycharges/getdeliverycharges',
  deleteDeliveryChargesUrl: 'deliverycharges/deletedeliverycharges',


  insertCouponUrl: 'coupon/insertcoupon',
  setCouponUrl: 'coupon/setcoupon',
  checkCouponUrl: 'coupon/checkcoupon',
  getCouponUrl: 'coupon/getcoupon',
  deleteCouponUrl: 'coupon/deletecoupon',


  insertPaymodeUrl: 'paymode/insertpaymode',
  setPaymodeUrl: 'paymode/setpaymode',
  checkPaymodeUrl: 'paymode/checkpaymode',
  getPaymodeUrl: 'paymode/getpaymode',
  deletePaymodeUrl: 'paymode/deletepaymode',

  
  insertCardTypeUrl: 'cardtype/insertcardtype',
  setCardTypeUrl: 'cardtype/setcardtype',
  checkCardTypeUrl: 'cardtype/checkcardtype',
  getCardTypeUrl: 'cardtype/getcardtype',
  deleteCardTypeUrl: 'cardtype/deletecardtype',

  insertProductGroupUrl: 'productgroup/insertproductgroup',
  setProductGroupUrl: 'productgroup/setproductgroup',
  checkProductGroupUrl: 'productgroup/checkproductgroup',
  getProductGroupUrl: 'productgroup/getproductgroup',
  deleteProductGroupUrl: 'productgroup/deleteproductgroup',

  insertNotificationUrl: 'notification/insertnotification',
  setNotificationUrl: 'notification/setnotification',
  checkNotificationUrl: 'notification/checknotification',
  getNotificationUrl: 'notification/getnotification',
  deleteNotificationUrl: 'notification/deletenotification',

  insertBranchUrl: 'branch/insertbranch',
  setBranchUrl: 'branch/setbranch',
  checkBranchUrl: 'branch/checkbranch',
  getBranchUrl: 'branch/getbranch',
  deleteBranchUrl: 'branch/deletebranch',


  insertUserRoleUrl: 'userrole/insertuserrole',
  setUserRoleUrl: 'userrole/setuserrole',
  checkUserRoleUrl: 'userrole/checkuserrole',
  getUserRoleUrl: 'userrole/getuserrole',
  getUserRoleRightsUrl: 'userrole/getuserrolerights',
  getUserRoleRightsUserUrl: 'userrole/getuserrolerightsbyuserid',
  deleteUserRoleUrl: 'userrole/deleteuserrole',

  insertUserUrl: 'user/insertuser',
  setUserUrl: 'user/setuser',
  checkUserUrl: 'user/checkuser',
  getUserUrl: 'user/getuser',
  getUserListviewUrl: 'user/getuserlistview',
  deleteUserUrl: 'user/deleteuser',
  getUserLoginUrl: 'user/getuserlogin',


  setContentmanagementUrl: 'contentmanagement/setcontentmanagement',
  getContentmanagementUrl: 'contentmanagement/getcontentmanagement',

  //------PAGE

  insertPageUrl: 'page/insertpage',
  setPageUrl: 'page/setpage',
  getPageUrl: 'page/getpage',
  getPageListViewUrl: 'page/getpagelistview',
  deletePageUrl: 'page/deletepage',
  getLayoutTypeUrl: 'page/getlayouttype',
  setPageLayoutOrderUrl: 'page/setpagelayoutorderno',

  //------ORDER
  getorderUrl: 'order/getorderlistview',
  getorderstatusbyorderUrl: 'order/getorderstatusbyorder',
  setorderUrl: 'order/setorder',
  getorderByorderidUrl: 'order/getorderbyorderid',
  getorderByDeliveryorderUrl: 'order/getorderbydeliveryorder',
  setorderunconfirmationUrl: 'order/setorderunconfirm',
  getorderProductListviewUrl: 'order/getorderproductlistview',

  setorderstatusUrl: 'order/setorderstatus',
  setorderconfirmUrl: 'order/setorderconfirm',
  setorderoufordeliveryUrl: 'order/setorderoutfordelivery',
  setorderitemcancelledUrl: 'order/setorderitemcancelled',
  setorderitemaddUrl: 'order/setorderitemadd',
  setorderitemqtychangesUrl: 'order/setorderitemqtychanges',
  setordercancelledUrl: 'order/setordercancelled',
  setorderremovedeliverychargeUrl: 'order/setorderremovedeliverycharge',
  setorderDeliveryDateUrl: 'order/setorderdeliverydate',
  setorderCustomerAvailDateUrl: 'order/setordercustomeravaildate',
  setorderPaidInfoUrl: 'order/setorderpaidinfo',
  setProductRequestStatusUrl: 'order/setproductrequeststatus',

  //------ORDER ADDRESS
  setorderaddressUrl: 'order/setorderaddress',
  getorderaddressByorderaddressidUrl: 'order/getorderaddressbyorderaddressid',

  //Print
  getinvoiceorderUrl: 'order/getinvoiceorder',
  getprintproductorderUrl: 'order/getprintproductorder',
  getprintorderUrl: 'order/getprintorder',
  getprintinvoiceorderUrl: 'order/getprintinvoiceorder',

  //Sales Invoice
  setsalesinvoiceUrl: 'order/setsalesinvoice',

  //------Delivery ORDER
  insertdeliveryorderUrl: 'deliveryorder/insertdeliveryorder',
  setdeliveryorderUrl: 'deliveryorder/setdeliveryorder',
  insertdeliveryorderdetailsUrl: 'deliveryorder/insertdeliveryorderdetails',
  deletedeliveryorderUrl: 'deliveryorder/deletedeliveryorder',
  getdeliveryorderUrl: 'deliveryorder/getdeliveryorderlistview',
  getdeliveryorderBydeliveryorderidUrl: 'deliveryorder/getdeliveryorderbyid',

  getshipmenttypeUrl: 'shipmenttype/getshipmenttype',

  setdeliveryorderassignedUrl: 'deliveryorder/setdeliveryorderassigned',
  setdeliveryorderpickedUrl: 'deliveryorder/setdeliveryorderpicked',
  setdeliveryorderdeliveredUrl: 'deliveryorder/setdeliveryorderdelivered',
  setdeliveryorderreceivedUrl: 'deliveryorder/setdeliveryorderreceived',
  setdeliveryorderreadytopickUrl: 'deliveryorder/setdeliveryorderreadytopick',

  setordererpinfoUrl: 'deliveryorder/setordererpinfo',

  getdeliveryorderstatusbyorderUrl: 'deliveryorder/getdeliveryorderstatusbyorder',

  getprintdeliveryorderUrl: 'order/getprintdeliveryorder',

  //----WALLET
  insertWalletAdminUrl: 'wallet/insertwalletadmin',
  setWalletAdminUrl: 'wallet/setwalletadmin',
  getWalletAdminUrl: 'wallet/getwalletadmin',
  deleteWalletAdminUrl: 'wallet/deletewalletadmin',
  getwalletUrl: 'wallet/getwalletlistview',

  //----LOYALTY
  insertLoyaltyAdminUrl: 'loyalty/insertloyaltyadmin',
  setLoyaltyAdminUrl: 'loyalty/setloyaltyadmin',
  getLoyaltyAdminUrl: 'loyalty/getloyaltyadmin',
  deleteLoyaltyAdminUrl: 'loyalty/deleteloyaltyadmin',
  getloyaltyUrl: 'loyalty/getloyaltylistview',


  //----CUSTOMER ADMIN    
  insertCustomerAdminUrl: 'customeradmin/insertcustomeradmin',
  setCustomerAdminUrl: 'customeradmin/setcustomeradmin',
  checkCustomerAdminUrl: 'customeradmin/checkcustomeradmin',
  getCustomerAdminUrl: 'customeradmin/getcustomeradmin',
  getCustomerAdminViewUrl: 'customeradmin/getcustomeradminview',
  deleteCustomerAdminUrl: 'customeradmin/deletecustomeradmin',
  getCustomerSerachUrl: 'customeradmin/getcustomersearchlist',
  getNotifymeUrl: 'customeradmin/getnotifyme',

  //----ADDRESS ADMIN    
  insertAddressAdminUrl: 'addressadmin/insertaddressadmin',
  setAddressAdminUrl: 'addressadmin/setaddressadmin',
  getAddressAdminUrl: 'addressadmin/getaddressadmin',
  deleteAddressAdminUrl: 'addressadmin/deleteaddressadmin',

  //------Offer
  insertOfferUrl: 'offer/insertoffer',
  setOfferUrl: 'offer/setoffer',
  checkOfferUrl: 'offer/checkoffer',
  insertOfferdetailsUrl: 'offer/insertofferdetails',
  deleteOfferUrl: 'offer/deleteoffer',
  getOfferUrl: 'offer/getofferlistview',
  getOfferByOfferidUrl: 'offer/getofferbyid',
  checkOfferProductsUrl: 'offer/checkofferproducts',


  //------PriceChange
  insertPriceChangeUrl: 'pricechange/insertpricechange',
  setPriceChangeUrl: 'pricechange/setpricechange',
  insertPriceChangedetailsUrl: 'pricechange/insertpricechangedetails',
  deletePriceChangeUrl: 'pricechange/deletepricechange',
  getPriceChangeUrl: 'pricechange/getpricechangelistview',
  getPriceChangeByPriceChangeidUrl: 'pricechange/getpricechangebyid',
  setPriceChangePostUrl: 'pricechange/setpricechangepost',

  insertImportUrl: 'pricechange/insertimport',

  setSettingsUrl: 'settings/setsettings',
  getSettingsUrl: 'settings/getsettings',

  //------Reason
  insertReasonUrl: 'reason/insertreason',
  setReasonUrl: 'reason/setreason',
  checkReasonUrl: 'reason/checkreason',
  getReasonUrl: 'reason/getreason',
  deleteReasonUrl: 'reason/deletereason',


  //------PopularSearch
  insertPopularSearchUrl: 'popularsearch/insertpopularsearch',
  setPopularSearchUrl: 'popularsearch/setpopularsearch',
  checkPopularSearchUrl: 'popularsearch/checkpopularsearch',
  getPopularSearchUrl: 'popularsearch/getpopularsearch',
  deletePopularSearchUrl: 'popularsearch/deletepopularsearch',

  //------Refund     
  getRefundByListviewUrl: 'refund/getrefundbylistview',
  getRefundUrl: 'refund/getrefund',
  setRefundByrefundidUrl: 'refund/setrefundbyrefundid',
  setRefundByAtriumUrl: 'dashboard/refundcardatriumpayment',
  
  //--------Common
  insertactivitylogUrl: 'common/insertactivitylog',

  //------Report
  getcartlistbyadminUrl: 'order/getcartlistbyadmin',
  getcartbycartidbyadminUrl: 'order/getcartbycartidbyadmin',

  //------Report
  getreportsalesUrl: 'report/getreportsales',
  getreportactivitylogUrl: 'report/getreportactivitylog',
  getreportfeedbackUrl: 'report/getreportfeedback',
  getfeedbackbyfeedbackidUrl: 'report/getfeedbackbyfeedbackid',
  
  //------Mail
  sendmailbyorderUrl: 'order/getmaildetailsbyorder',


  //------Notification
  sendnotificationbyorderUrl: 'order/getnotificationdetailsbyorder',

  //------Dashboard
  getdashboardadminUrl: 'order/getdashboardadmin',



}


