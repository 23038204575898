import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ExportgridComponent } from '../grid/exportgrid/exportgrid.component';
import { UpdateproductrequestComponent } from './updateproductrequest/updateproductrequest.component';

@Component({
  selector: 'app-productrequest',
  templateUrl: './productrequest.component.html',
  styleUrls: ['./productrequest.component.css']
})
export class ProductrequestComponent implements OnInit {


  requestColl: any = [];

  areaColl: any = []
  areaid: any = null;

  isadd: boolean = false;
  isedit: boolean = false;
  isdelete: boolean = false;
  isview: boolean = false;
  isprint: boolean = false;
  isexport: boolean = false;

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "ProductRequest";

  filterby: any = "OPEN";

  searchtxt: any = "";
  columnDefs = [
    { headerName: 'Area', field: 'areaname', },
    { headerName: 'Name', field: 'productname', },
    { headerName: 'Barcode', field: 'barcode', },
    { headerName: 'Brand', field: 'brand', },
    { headerName: 'Mobile', field: 'mobile', },
    { headerName: 'Request On', field: 'createdon',type:'datetime' },
    { headerName: 'Status', field: 'productstatus', },
    { headerName: 'Remakrs', field: 'remarks', },
  ]
  gridOptions = {
    enableactioncol: false,
    columnDefs: this.columnDefs,
    pageindex: 1
  };


  constructor(private appService: DhukanDataService, public router: Router, private productFindService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService, private toastr: ToastrService, private loginService: LoginDataService) { this.fnServiceChanges(); }

  //User Check
  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.fnUserFailedForm();
      }
    });
  }





  ngOnInit() {
    try {


      if (this.loginService.fnCheckUser()) {
        this.fnCheckPrivilage();
        if (this.isview) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }


    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    this.fngetArea()
    this.fngetProductRequestListView();
  }

  fnCheckPrivilage() {
    this.isadd = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE');
    this.isedit = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER');
    this.isdelete = this.loginService.fnCheckUserRoleAction(this.formname, 'DELETE');
    this.isview = this.loginService.fnCheckUserRoleAction(this.formname, 'VIEW');
    this.isprint = this.loginService.fnCheckUserRoleAction(this.formname, 'PRINT');
    this.isexport = this.loginService.fnCheckUserRoleAction(this.formname, 'EXPORT');
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {

  }


  //Grid


  //Event  
  fnActionEditClick(data) {
  
    
    const modalRef = this.productFindService.open(UpdateproductrequestComponent, { size: <any>'l', backdrop: 'static',keyboard:false },);
    modalRef.componentInstance.doctype = "";
    modalRef.componentInstance.productrequestBo = data;
    modalRef.componentInstance.productrequestid = data.productrequestid;
    modalRef.componentInstance.productstatus = data.productstatus;
    modalRef.componentInstance.remarks = data.remarks;    
    modalRef.componentInstance.isclosed = data.isclosed;    
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {
      this.fngetProductRequestListView()

    });


  }

  fnActionExportClick() {
    const modalRef = this.productFindService.open(ExportgridComponent, { size: <any>'xl', backdrop: 'static' });
    modalRef.componentInstance.doctype = this.formname;
    modalRef.componentInstance.columnDefs = this.columnDefs;
    modalRef.componentInstance.contentColl = this.requestColl;
    modalRef.componentInstance.fnSetTable();
    modalRef.componentInstance.emitData.subscribe((receivedEntry) => {


    });
  }


  fngetArea() {        
    var data = {
      'areaid':0,            
    }
    this.appService.getArea(data)
    .subscribe(
      (res)=>{                  
        this.areaColl=res.json().result;   
    },
    (err)=>{          
      this.toastr.error('Error', err);
    })
  }


  fngetProductRequestListView() {
    if (this.showProgress) {
      return;
    }


    this.fnShowProgress();
    var data = {
      'filterby': this.filterby,
      'areaid':this.areaid
    }
    this.appService.getProductRequestListview(data)
      .subscribe(
        (res) => {
          this.fnHideProgress();
          if (res.json().status == 200) {
            this.requestColl = res.json().result;
            this.fnSetDataTable();
          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }
        },
        (err) => {
          this.fnHideProgress();
          this.toastr.error('Error', err);
        })
  }



  private fnSetDataTable() {




  }

  fnResetGrid(data) {
    this.searchtxt = data;
    this.gridOptions.pageindex = 1
  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }








}
