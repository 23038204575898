import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-addrefund',
  templateUrl: './addrefund.component.html',
  styleUrls: ['./addrefund.component.css']
})
export class AddrefundComponent implements OnInit {


  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;


  //Edit Get Values
  refundBO: any = {};
  refundColl: any = [];
  refundid: number = 0

  paymodeColl: any = [];

  refundform = new FormGroup({
    refundpaymodeid: new FormControl(null, Validators.required),
    refunddate: new FormControl(null, Validators.required),
    refundrefno: new FormControl(''),
    refunddesc: new FormControl(''),
    refundid: new FormControl(0),
  });


  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Refund";

  constructor(private appService: DhukanDataService, private route: ActivatedRoute,
    public router: Router, private toastr: ToastrService, private loginService: LoginDataService, private datePipe: DatePipe,) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.refundid = params.id
      }
    });
    this.fnServiceChanges();
  }


  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }

  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    try {
      this.isaddmode = true;


      if (this.refundid > 0) {
        this.fngetRefund();
      }



      this.fngetPaymode();
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.refundid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }


  fnInitializeRefundFormGroup() {
    this.refundform.setValue({
      refundpaymodeid: null,
      refunddate: null,
      refundrefno: '',
      refunddesc: '',
      refundid: 0,
    })
  }



  fngetPaymode() {

    var data = {
      'paymodeid': 0,
    }
    this.appService.getPaymode(data)
      .subscribe(
        (res) => {

          if (res.json().status == 200) {
            this.paymodeColl = res.json().result;

          }
          else if (res.json().status == 400) {
            this.toastr.error('Error', res.json().err_field);
          }

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  //Get 
  fngetRefund() {
    var data = {
      'refundid': this.refundid,
    }
    this.appService.getRefund(data)
      .subscribe(
        (res) => {
          this.refundColl = res.json().result;
          this.refundBO = this.refundColl[0];



          this.refundform.patchValue({
            refundid: this.refundBO.refundid,

          })
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }



  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      // if(this.refundform.valid){
      //Check
      var data = {
        'refundid': this.refundid,
        'refundpaymodeid': this.refundform.get('refundpaymodeid').value,
        refunddate: this.fnFormatDatetime(this.refundform.get('refunddate').value),
        refundrefno: this.refundform.get('refundrefno').value,
        refunddesc: this.refundform.get('refunddesc').value,
        'modifiedby': this.loginService.fnGetModUserId(),
      }
      if (this.refundform.get('refundid').value > 0) {
        //Update Department
        this.fnSetRefund(data);
      }

      // }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }



  //Set
  fnSetRefund(data) {

   this.fnShowProgress()


    this.appService.setRefundByAtrium(data).subscribe(
      (res) => {
        
        if (res.json().status == 200) {
          var result = res.json().result;
          data.refundrefno = result.txid;
          this.appService.setRefundByRefundid(data).subscribe(
            (res) => {
              this.fnHideProgress()
              if (res.json().result != "") {
                this.toastr.success('', "The refund has been updated successfully.");

                this.fnInsertActivityLog('EDIT', this.refundid, data.paymode);

                this.fnCloseRefund();
              }
            },
            (err) => {
              this.fnHideProgress()
              this.toastr.error('Error', err);
            });
        }
        else {
          this.fnHideProgress()
          this.toastr.error('Error', res.json().err_message);
        }
      },
      (err) => {
        this.fnHideProgress()
        this.toastr.error('Error', err);
      });




  }


  //Close
  fnCloseRefund() {
    try {
      this.router.navigate(['/Refund']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnViewOrder(deptid) {
    try {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          'id': deptid,
        }
      }

      this.router.navigate(['/AddOrders'], navigationExtras);



    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }


  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });

    } catch {

    }
  }


  fnFormatDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {
        return this.datePipe.transform(dt, "yyyy-MM-dd")
      }

    } else {
      return null
    }

  }

  fnConvertDatetime(dt) {
    if (dt) {
      if (dt === "") {
        return null
      }
      else {


        var dateParts = dt.split("-");

        var sdate = new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2] + 1);

        sdate.setMinutes(sdate.getMinutes() + sdate.getTimezoneOffset());



        return sdate;
      }

    } else {
      return null
    }

  }



  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }

  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }




}
