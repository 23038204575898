import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginDataService } from '../services/dhukan/login-data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {


  userBO:any={};
  userrolerightsColl:any=[];
  userView:any={};

  constructor(public router: Router,private loginService: LoginDataService) {
    this.fnServiceChanges();
   }

  fnServiceChanges(){    
    this.userrolerightsColl = this.loginService.userrolerightsColl; 
    this.loginService.serviceloginChange.subscribe((value) => {
        this.userView = value                 
        if(this.userView.userBO){                
          this.userBO= this.userView.userBO                
        }      
        
    }); 
  }

  ngOnInit() {
    if(localStorage.adtoken === undefined){
      this.router.navigate(['/Login']);
    }
    else{
      this.loginService.fnGetUserRoleList();
    }

   
  }


  fnLogout(){
    localStorage.removeItem("adtoken");
    this.router.navigate(['/Login']);
    this.loginService.islogin = true;
    this.loginService.serviceloginscreenChange.next(true);
  }

}
