import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router,ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductfindComponent } from '../../productfind/productfind.component';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-adddepartment',
  templateUrl: './adddepartment.component.html',
  styleUrls: ['./adddepartment.component.css']
})
export class AdddepartmentComponent implements OnInit {
   //Mode Values
   isaddmode:boolean=false;
   iseditmode:boolean=false;
   isdeletemode:boolean=false;
   isviewmode:boolean=false;

   //Save Validation
   submitted:boolean;
   successfully:boolean;

   //set image
   image;
   strImage: any;

   addimage;
   addstrImage: any;

   //Edit Get Values
   deptColl:any=[];
   deptid:number=0
   picurl:string="";
   picname:string="";
   addpicurl:string="";
   addpicisclear:boolean=false;

   tdeptColl:any=[];
   categoryColl:any=[];
   subcategoryColl:any=[];
   brandColl:any=[];

   linktypeColl:any=[];
   pageColl:any=[];


   deptform=new FormGroup({
    deptname:new FormControl('',Validators.required),         
    deptid:new FormControl(0), 
    image:new FormControl(null),        
    addimage:new FormControl(null),        
    isdisplay:new FormControl(false),     
    sequenceno:new FormControl(0),   
    linktype:new FormControl(''), 
      linktargetid:new FormControl(null), 
      linkurl:new FormControl(''),       
      linkhtml:new FormControl(''),          
    inactive:new FormControl(false), 
    tdeptid:new FormControl(null), 
    categoryid:new FormControl(null), 
    subcategoryid:new FormControl(null), 
    brandid:new FormControl(null), 
    productid:new FormControl(null), 
    productname:new FormControl(''), 
    pageid:new FormControl(null),     
    categorypageid:new FormControl(null),     
   });


   isloading:boolean=false;
isprivilage:boolean=false;  
showProgress:boolean=false;
formname:String="Department";

constructor(private appService: DhukanDataService,private route: ActivatedRoute,
      public router: Router,private toastr: ToastrService, private productFindService: NgbModal,private loginService:LoginDataService) { 
        this.route.queryParams.subscribe(params => {
          if(params.id!==undefined){
            this.deptid = params.id
          }          
        });
        this.fnServiceChanges();
}
  

fnServiceChanges(){
  this.loginService.serviceloginChange.subscribe((value) => {
        if(this.loginService.fnCheckUser()){
         
          if(this.fnCheckUserPririvilage()){
            this.isloading=true;
            this.fnOnInitForm();
          }
          else{
            this.fnUserFailedForm();
          }
            
        }
        else{
          this.fnUserFailedForm();
        }
  });
}

ngOnInit() {
    try{
      if(this.loginService.fnCheckUser()){ 
        if(this.fnCheckUserPririvilage()){       
          this.isloading=true;
          this.fnOnInitForm();
        }
        else{
          this.fnUserFailedForm();
        }
      }
      else{
        this.loginService.fnGetUserRoleList();
      }
              
    }catch(ex){
      this.fnShowErrorMsg(ex);
    }
}

fnOnInitForm(){
      try{
          this.isaddmode=true;
          this.deptform.reset();
          this.fnInitializeDeptFormGroup(); 

          if(this.deptid>0){
            this.fngetDepartment();
          }

          this.fngetLinkType();
          this.fngetPage();
      }catch(ex){
        this.fnShowErrorMsg(ex);
      }
}




fnCheckUserPririvilage(){
  var _check:boolean=false;
  if (this.deptid>0){
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'ALTER')
  }else{
    _check= this.loginService.fnCheckUserRoleAction(this.formname,'CREATE')
  }

  return _check;
}

fnUserFailedForm(){
  this.isprivilage=true;
}


fnInitializeDeptFormGroup(){
      this.deptform.setValue({
        deptname:'',
        deptid:0,
        image:null,
        addimage:null,
        isdisplay:false,
        sequenceno:0, 
        linktype:'', 
        linktargetid:null, 
        linkurl:'',  
        linkhtml:'',       
        inactive:false,  
        tdeptid:null, 
        categoryid:null, 
        subcategoryid:null, 
        brandid:null, 
        productid:null,  
        productname:'',  
        pageid:null,    
        categorypageid:null  
      })
}


fngetLinkType() { 
  let _type=[
    {display:"Department",name:"DEPARTMENT"},
    {display:"Category",name:"CATEGORY"},
    {display:"SubCategory",name:"SUBCATEGORY"},
    {display:"Brand",name:"BRAND"},
    {display:"Product",name:"PRODUCT"},
    {display:"Html",name:"HTML"}
  ]

  this.linktypeColl=_type;
}

//Get 
fngetDepartment() {        
        var data = {
          'deptid':this.deptid,            
        }
        this.appService.getDepartment(data)
        .subscribe(
          (res)=>{                  
            this.deptColl=res.json().result;
            var editdata = {
              'deptid':this.deptColl[0].deptid,
              'deptname':this.deptColl[0].deptname, 
              'inactive':this.deptColl[0].inactive,              
              'picurl':this.deptColl[0].picurl,            
              'picname':this.deptColl[0].picname,  
              'addpicurl':this.deptColl[0].addpicurl,          
              'isdisplay':this.deptColl[0].isdisplay,          
              'sequenceno':this.deptColl[0].sequenceno, 
              'linktype':this.deptColl[0].linktype, 
              'linktargetid':this.deptColl[0].linktargetid, 
              'linkurl':this.deptColl[0].linkurl, 
              'linkhtml':this.deptColl[0].linkhtml,   
              'productname':this.deptColl[0].productname,    
              'pageid':this.deptColl[0].pageid,   
              'categorypageid':this.deptColl[0].categorypageid,       
            }
            this.fnEditMode(editdata);
        },
        (err)=>{          
          this.toastr.error('Error', err);
        })
}


fngetTDepartment() {        
  var data = {
    'deptid':0,            
  }
  this.appService.getDepartment(data)
  .subscribe(
    (res)=>{                  
      this.tdeptColl=res.json().result;     
    },
  (err)=>{          
    this.toastr.error('Error', err);
  })
}

fngetCategory() {        
 
  var data = {
    'categoryid':0,        
    'deptid':0,     
  }


    this.appService.getCategory(data)
    .subscribe(
      (res)=>{                  
        this.categoryColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}

fngetSubCategory() {        
 
  var data = {
    'categoryid':0,        
    'subcategoryid':0,     
  }


    this.appService.getSubCategory(data)
    .subscribe(
      (res)=>{                  
        this.subcategoryColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}

fngetBrand() {        
 
  var data = {
    'brandid':0            
  }


    this.appService.getBrand(data)
    .subscribe(
      (res)=>{                  
        this.brandColl=res.json().result;     
      },
    (err)=>{          
      this.toastr.error('Error', err);
    })
 
}

  
fngetPage() {   
  var data = {
        'pageid': 0,            
  }
  this.appService.getPageByListView(data)
      .subscribe(
        (res)=>{                  
          if(res.json().status==200){                          
            this.pageColl=res.json().result;          
          }
          else if(res.json().status==400){                          
            this.toastr.error('Error', res.json().err_field);
          }
         
         
      },
        (err)=>{          
          this.toastr.error('Error', err);
        })
}



//Save Btn Click
fnSaveBtnClick(){
      try{
        this.submitted=true;
        this.successfully=false;
        //Validate
        if(this.deptform.valid){
            //Check
            var data = {
                  'deptid': this.deptid,       
                  'deptname':this.deptform.get('deptname').value,            
                  'inactive': this.deptform.get('inactive').value, 
                  'createdby': this.loginService.fnGetModUserId(),    
                  'modifiedby':this.loginService.fnGetModUserId(),    
                  'image': this.strImage,
                  'picurl': this.picurl,
                  'picname': this.picname,                  
                  'addimage':this.addstrImage,
                  'addpicurl': this.addpicurl,
                  'addpicclear': this.addpicisclear,               
                  'isdisplay': this.deptform.get('isdisplay').value,  
                  'sequenceno': this.deptform.get('sequenceno').value,  
                  'linktype':this.deptform.get('linktype').value,    
                  'linktargetid':this.fnGetLinkTypeId(this.deptform.get('linktype').value),            
                  'linkurl':this.fnGetLinkTypeUrl(this.deptform.get('linktype').value),            
                  'linkhtml':this.deptform.get('linkhtml').value,   
                  'pageid':this.deptform.get('pageid').value,  
                  'categorypageid':this.deptform.get('categorypageid').value,  
            }
                     
            this.appService.fnCheckDepartment(data).subscribe(
              (res)=>{
                  var _checkresult=res.json().result[0].checkfield;
                
                  if(_checkresult==="done"){
                        if (this.deptform.get('deptid').value>0){
                          //Update Department
                          this.fnSetDepartment(data);
                        }
                        else{
                          //Insert Department
                          this.fnInsertDepartment(data);
                        }
                  }
                  else{        
                        if(_checkresult==="name"){
                            this.deptform.controls['deptname'].setErrors({'notfound': true});
                        }
                  }
            },
            (err)=>{
                this.toastr.error('Error', err);           
            });
         }
      }catch(ex){
        this.fnShowErrorMsg(ex);
      }
}

//Insert
fnInsertDepartment(data){    
    
      this.appService.fnInsertDepartment(data).subscribe(
        (res)=>{
        if(res.json().result!=""){
          this.toastr.success('', "The new department has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT',0,data.deptname);

          this.fnCloseDepartment();
        }},
        (err)=>{
          this.toastr.error('Error', err);
        });

}

//Set
fnSetDepartment(data){    

  this.appService.fnSetDepartment(data).subscribe(
    (res)=>{
    if(res.json().result!=""){
        this.toastr.success('', "The department has been updated successfully.");

        this.fnInsertActivityLog('EDIT',this.deptid,data.deptname);

        this.fnCloseDepartment();        
    }},
    (err)=>{
      this.toastr.error('Error', err);
    });

}

//Close
fnCloseDepartment(){
  try{
     this.router.navigate(['/Department']);
  }catch(ex){
      this.fnShowErrorMsg(ex);
  }
}

private fnEditMode(deptBO){
    this.deptform.setValue({
      deptname:deptBO.deptname,
      deptid:deptBO.deptid,
      inactive:deptBO.inactive, 
      isdisplay:deptBO.isdisplay, 
      sequenceno:deptBO.sequenceno, 
      linktype:deptBO.linktype, 
      linktargetid:deptBO.linktargetid, 
      linkurl:deptBO.linkurl,       
      linkhtml:deptBO.linkhtml,
      addimage:null, 
      image:null,     
      tdeptid:null, 
      categoryid:null, 
      subcategoryid:null, 
      brandid:null,  
      productid:null,  
      productname:deptBO.productname,   
      pageid:deptBO.pageid,
      categorypageid:deptBO.categorypageid 
    });
    this.picurl =deptBO.picurl;
    this.picname =deptBO.picname;

    this.addpicurl=deptBO.addpicurl;
    
    this.fnLinkTypeChange();

 let type=this.deptform.get('linktype').value;
  if(type==="DEPARTMENT"){
    this.deptform.patchValue({tdeptid:deptBO.linktargetid});
  }
  else if(type==="CATEGORY"){
    this.deptform.patchValue({categoryid:deptBO.linktargetid});
  }
  else if(type==="SUBCATEGORY"){
    this.deptform.patchValue({subcategoryid:deptBO.linktargetid});
  } 
  else if(type==="BRAND"){
    this.deptform.patchValue({brandid:deptBO.linktargetid});
  } 
  else if(type==="PRODUCT"){
    this.deptform.patchValue({productid:deptBO.linktargetid});
  } 


  this.iseditmode=true;
  this.isdeletemode=false;
  this.isviewmode=false;
}

fnLinkTypeChange(){
  let type=this.deptform.get('linktype').value;
  if(type==="DEPARTMENT"){
    this.fngetTDepartment();
  }
  else if(type==="CATEGORY"){
    this.fngetCategory();
  }
  else if(type==="SUBCATEGORY"){
    this.fngetSubCategory();
  }
  else if(type==="BRAND"){
    this.fngetBrand();
  }
}

fnSelectProduct(){
 
  const modalRef = this.productFindService.open(ProductfindComponent ,{ size: <any>'xl' });
  modalRef.componentInstance.doctype ="SELECT PRODUCT" ;

  modalRef.componentInstance.emitData.subscribe((receivedEntry) => {

    for(let wbo of receivedEntry){

      this.deptform.patchValue({ 
        productid:wbo.productid,        
        productname:wbo.productname,
      });

    }
  });

}



fnGetLinkTypeId(type){
  if(type==="DEPARTMENT"){
    return this.deptform.get('tdeptid').value;
  }
  else if(type==="CATEGORY"){
    return this.deptform.get('categoryid').value;
  }
  else if(type==="SUBCATEGORY"){
    return this.deptform.get('subcategoryid').value;
  }
  else if(type==="BRAND"){
    return this.deptform.get('brandid').value;
  }
  else if(type==="PRODUCT"){
    return this.deptform.get('productid').value;
  } 
  else {
    return null;
  }
}

fnGetLinkTypeUrl(type){
  let para="";

  if(type==="DEPARTMENT"){
    for(let wbo of this.tdeptColl){
      if(this.deptform.get('tdeptid').value===wbo.deptid){
        para=wbo.deptid+"-"+this.fnRemoveSpecialCharater(wbo.deptname);        
        return para        
      }
    }
  }
  else if(type==="CATEGORY"){
    for(let wbo of this.categoryColl){
      if(this.deptform.get('categoryid').value===wbo.categoryid){
        para=wbo.deptid+"-"+this.fnRemoveSpecialCharater(wbo.deptname);        
        para=para+"/"+wbo.categoryid+"-"+this.fnRemoveSpecialCharater(wbo.categoryname);        
        return para        
      }
    }
  }
  else if(type==="SUBCATEGORY"){
    for(let wbo of this.subcategoryColl){
      if(this.deptform.get('subcategoryid').value===wbo.subcategoryid){
        para=wbo.deptid+"-"+this.fnRemoveSpecialCharater(wbo.deptname);        
        para=para+"/"+wbo.categoryid+"-"+this.fnRemoveSpecialCharater(wbo.categoryname);        
        para=para+"/"+wbo.subcategoryid+"-"+this.fnRemoveSpecialCharater(wbo.subcategoryname);        
        return para        
      }
    }
  } 
  else if(type==="BRAND"){
    for(let wbo of this.brandColl){
      if(this.deptform.get('brandid').value===wbo.brandid){
        para=wbo.brandid+"-"+this.fnRemoveSpecialCharater(wbo.brandname);        
        return para        
      }
    }
  }
  else if(type==="PRODUCT"){

    para=this.deptform.get('productid').value+"-"+this.fnRemoveSpecialCharater(this.deptform.get('productname').value);        
    return para   
  } 
  else {
    return para;
  }
}

//Image Import
changeListener($event): void {
 try{
    this.readThis($event.target);
  }catch(ex){
    this.fnShowErrorMsg(ex);
  }
}

readThis(inputValue: any): void {
 
  var file: File = inputValue.files[0];
  var myReader: FileReader = new FileReader();

  myReader.onloadend = (e) => {
      this.image = myReader.result;
      this.strImage = this.image.split(',')[1];
  }
  myReader.readAsDataURL(file);
}

removeimage(){
  this.image =null;
  this.strImage =null;
  this.picurl="";
}


fnOpenFileBrowser(event:any){

  event.preventDefault();

  let element:HTMLElement=document.getElementById('image') as HTMLElement;
  element.click();

}


//AddImage Import
changeListenerAd($event): void {
  try{
     this.readThisAd($event.target);
   }catch(ex){
     this.fnShowErrorMsg(ex);
   }
 }
 
 readThisAd(inputValue: any): void {
  
   var file: File = inputValue.files[0];
   var myReader: FileReader = new FileReader();
 
   myReader.onloadend = (e) => {
       this.addimage = myReader.result;
       this.addstrImage = this.addimage.split(',')[1];
   }
   myReader.readAsDataURL(file);
 }
 
 removeimageAd(){
   this.addimage =null;
   this.addstrImage =null;
   this.addpicurl="";
   this.addpicisclear=true;
 }
 
 
 fnAdOpenFileBrowser(event:any){
 
   event.preventDefault();
 
   let element:HTMLElement=document.getElementById('addimage') as HTMLElement;
   element.click();
 
 }

 private fnRemoveSpecialCharater(str){
  str = str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
  str = str.toString().replace(/ /g, "");
  return str;
}


fnInsertActivityLog(action,refno,desc){
  try{
    var data={
      logtype:action,
      logat:this.formname,
      refnum:refno,
      logdescr:desc,
      logby:this.loginService.fnGetModUserId(),
    }
  

    this.appService.fnInsertActivity(data).subscribe(
      (res)=>{
      },
      (err)=>{
     
      });

  }catch{

  }
}


private fnShowProgress(){
  this.showProgress=true;
}
private fnHideProgress(){
  this.showProgress=false;
}


private  fnShowErrorMsg(ex){
  this.toastr.warning('Internal Error', ex);       
}


}
