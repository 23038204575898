import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DhukanDataService } from 'src/app/services/dhukan/dhukan-data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MapsAPILoader } from '@agm/core';
import { LoginDataService } from 'src/app/services/dhukan/login-data.service';

@Component({
  selector: 'app-addarea',
  templateUrl: './addarea.component.html',
  styleUrls: ['./addarea.component.css']
})
export class AddareaComponent implements OnInit {

  //MAP
  @ViewChild('search', { static: false })
  public searchElementRef: ElementRef;

  //Mode Values
  isaddmode: boolean = false;
  iseditmode: boolean = false;
  isdeletemode: boolean = false;
  isviewmode: boolean = false;

  //Save Validation
  submitted: boolean;
  successfully: boolean;

  //set image
  image;
  strImage: any;

  picurl: string = "";
  picname: string = "";

  list = [];
  //Edit Get Values
  cityColl: any = [];
  areaColl: any = [];
  areaid: number = 0

  areapaymentColl: any = [];
  areadepartmentColl: any = [];






  areaform = new FormGroup({
    areaname: new FormControl('', Validators.required),
    areaid: new FormControl(0),
    cityid: new FormControl(null, Validators.required),
    pincode: new FormControl(''),
    latitude: new FormControl(0),
    longitude: new FormControl(0),
    iconurl: new FormControl(''),
    iconimage: new FormControl(null),
    isdisplay: new FormControl(false),
    radius: new FormControl(0),
    rptcompanyname: new FormControl(null),
    address1: new FormControl(null),
    address2: new FormControl(null),
    address3: new FormControl(null),
    address4: new FormControl(null),
    address5: new FormControl(null),
    trnno: new FormControl(null),
    rptheadername: new FormControl(null),
    footer1: new FormControl(null),
    footer2: new FormControl(null),
    footer3: new FormControl(null),
    footer4: new FormControl(null),
    footer5: new FormControl(null),
    footer6: new FormControl(null),
    footer7: new FormControl(null),
    footer8: new FormControl(null),
    footer9: new FormControl(null),
    footer10: new FormControl(null),
    inactive: new FormControl(false),
  });

  isloading: boolean = false;
  isprivilage: boolean = false;
  showProgress: boolean = false;
  formname: String = "Area";

  latitude = 13.0827;
  longitude = 80.2707;

  markers = [];
  mapType = 'roadmap';

  constructor(private appService: DhukanDataService, private route: ActivatedRoute,
    public router: Router, private toastr: ToastrService,
    private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, private loginService: LoginDataService) {
    this.route.queryParams.subscribe(params => {
      if (params.id !== undefined) {
        this.areaid = params.id
      }
    });

    this.fnServiceChanges();
  }

  fnServiceChanges() {
    this.loginService.serviceloginChange.subscribe((value) => {
      if (this.loginService.fnCheckUser()) {

        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }

      }
      else {
        this.fnUserFailedForm();
      }
    });
  }


  ngOnInit() {
    try {
      if (this.loginService.fnCheckUser()) {
        if (this.fnCheckUserPririvilage()) {
          this.isloading = true;
          this.fnOnInitForm();
        }
        else {
          this.fnUserFailedForm();
        }
      }
      else {
        this.loginService.fnGetUserRoleList();
      }

    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  fnOnInitForm() {
    this.isaddmode = true;
    this.areaform.reset();
    this.fnInitializeAreaFormGroup();
    if (this.areaid > 0) {
      this.fngetArea();

    }
    this.fngetAreaDepartment()
    this.fngetAreaPayment()
    this.fngetCity();
    this.fnSetAutoCompleteArea();
  }

  fnCheckUserPririvilage() {
    var _check: boolean = false;
    if (this.areaid > 0) {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'ALTER')
    } else {
      _check = this.loginService.fnCheckUserRoleAction(this.formname, 'CREATE')
    }

    return _check;
  }

  fnUserFailedForm() {
    this.isprivilage = true;
  }

  fnInitializeAreaFormGroup() {
    this.areaform.setValue({
      areaname: '',
      areaid: 0,
      cityid: null,
      pincode: '',
      latitude: 0,
      longitude: 0,
      iconurl: '',
      iconimage: null,
      radius: 0,
      isdisplay: false,
      rptcompanyname: null,
      address1: null,
      address2: null,
      address3: null,
      address4: null,
      address5: null,
      trnno: null,
      rptheadername: null,
      footer1: null,
      footer2: null,
      footer3: null,
      footer4: null,
      footer5: null,
      footer6: null,
      footer7: null,
      footer8: null,
      footer9: null,
      footer10: null,
      inactive: false,
    })
  }

  fnSetAutoCompleteArea() {
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        componentRestrictions: { country: 'IN' },
        types: ["establishment"]
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }



          this.areaform.setValue({
            areaname: place.name,
            latitude: parseFloat(place.geometry.location.lat().toString()).toFixed(4),
            longitude: parseFloat(place.geometry.location.lng().toString()).toFixed(4),
            areaid: this.areaform.get('areaid').value,
            cityid: this.areaform.get('cityid').value,
            inactive: this.areaform.get('inactive').value,
            pincode: this.areaform.get('pincode').value,
          });


          //set latitude, longitude and zoom
          //this.latitude = place.geometry.location.lat();
          // this.longitude = place.geometry.location.lng();
          //this.position = place.address_components[0].short_name;
          // this.zoom = 12;
        });
      });
    });
  }


  //Get 
  fngetCity() {
    var data = {
      'cityid': 0,
      'warehouseid': 0
    }
    this.appService.getCity(data)
      .subscribe(
        (res) => {
          this.cityColl = res.json().result;
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  //Get 
  fngetArea() {
    var data = {
      'areaid': this.areaid,
    }
    this.appService.getArea(data)
      .subscribe(
        (res) => {
          this.areaColl = res.json().result;
          var editdata = {
            'areaid': this.areaColl[0].areaid,
            'areaname': this.areaColl[0].areaname,
            'cityid': this.areaColl[0].cityid,
            'latitude': this.areaColl[0].latitude,
            'longitude': this.areaColl[0].longitude,
            'pincode': this.areaColl[0].pincode,
            iconurl: this.areaColl[0].iconurl,
            radius: this.areaColl[0].radius,
            rptcompanyname: this.areaColl[0].rptcompanyname,
            address1: this.areaColl[0].address1,
            address2: this.areaColl[0].address2,
            address3: this.areaColl[0].address3,
            address4: this.areaColl[0].address4,
            address5: this.areaColl[0].address5,
            trnno: this.areaColl[0].trnno,
            rptheadername: this.areaColl[0].rptheadername,
            footer1: this.areaColl[0].footer1,
            footer2: this.areaColl[0].footer2,
            footer3: this.areaColl[0].footer3,
            footer4: this.areaColl[0].footer4,
            footer5: this.areaColl[0].footer5,
            footer6: this.areaColl[0].footer6,
            footer7: this.areaColl[0].footer7,
            footer8: this.areaColl[0].footer8,
            footer9: this.areaColl[0].footer9,
            footer10: this.areaColl[0].footer10,
            'isdisplay': this.areaColl[0].isdisplay,
            'inactive': this.areaColl[0].inactive,
          }
          this.fnEditMode(editdata);
        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }




  //Get 
  fngetAreaDepartment() {
    var data = {
      'areaid': this.areaid,
    }
    this.appService.getAreaDepartment(data)
      .subscribe(
        (res) => {
          this.areadepartmentColl = res.json().result;

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }

  fngetAreaPayment() {
    var data = {
      'areaid': this.areaid,
    }
    this.appService.getAreaPayment(data)
      .subscribe(
        (res) => {
          this.areapaymentColl = res.json().result;

        },
        (err) => {
          this.toastr.error('Error', err);
        })
  }


  //Save Btn Click
  fnSaveBtnClick() {
    try {
      this.submitted = true;
      this.successfully = false;
      //Validate
      if (this.areaform.valid) {
        //Check
        var data = {
          'areaid': this.areaid,
          'areaname': this.areaform.get('areaname').value,
          'cityid': this.areaform.get('cityid').value,
          'pincode': this.areaform.get('pincode').value,
          'latitude': this.areaform.get('latitude').value,
          'longitude': this.areaform.get('longitude').value,
          'iconimage': this.strImage,
          'iconurl': this.picurl,
          'isdisplay': this.areaform.get('isdisplay').value,
          'radius': this.areaform.get('radius').value,
          'areadepartmentColl': this.areadepartmentColl,
          'areapaymentmodeColl': this.areapaymentColl,
          rptcompanyname: this.areaform.get('rptcompanyname').value,
            address1: this.areaform.get('address1').value,
            address2: this.areaform.get('address2').value,
            address3: this.areaform.get('address3').value,
            address4:this.areaform.get('address4').value,
            address5: this.areaform.get('address5').value,
            trnno: this.areaform.get('trnno').value,
            rptheadername: this.areaform.get('rptheadername').value,
            footer1:this.areaform.get('footer1').value,
            footer2: this.areaform.get('footer2').value,
            footer3: this.areaform.get('footer3').value,
            footer4: this.areaform.get('footer4').value,
            footer5: this.areaform.get('footer5').value,
            footer6: this.areaform.get('footer6').value,
            footer7: this.areaform.get('footer7').value,
            footer8: this.areaform.get('footer8').value,
            footer9: this.areaform.get('footer9').value,
            footer10: this.areaform.get('footer10').value,
          'inactive': 0,
          'createdby': this.loginService.fnGetModUserId(),
          'modifiedby': this.loginService.fnGetModUserId(),
        }
        this.appService.fnCheckArea(data).subscribe(
          (res) => {
            var _checkresult = res.json().result[0].checkfield;

            if (_checkresult === "done") {
              if (this.areaform.get('areaid').value > 0) {
                //Update Department
                this.fnSetArea(data);
              }
              else {
                //Insert Department
                this.fnInsertArea(data);
              }
            }
            else {
              if (_checkresult === "name") {
                this.areaform.controls['areaname'].setErrors({ 'notfound': true });
              }
            }
          },
          (err) => {
            this.toastr.error('Error', err);
          });
      }
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  //Insert
  fnInsertArea(data) {

    this.appService.fnInsertArea(data).subscribe(
      (res) => {

        if (res.json().result != "") {
          this.toastr.success('', "The new area has been added successfully.Insert Successfully");

          this.fnInsertActivityLog('INSERT', 0, data.areaname);

          this.fnCloseArea();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Set
  fnSetArea(data) {

    this.appService.fnSetArea(data).subscribe(
      (res) => {
        if (res.json().result != "") {
          this.toastr.success('', "The area has been updated successfully.");

          this.fnInsertActivityLog('EDIT', this.areaid, data.areaname);

          this.fnCloseArea();
        }
      },
      (err) => {
        this.toastr.error('Error', err);
      });

  }

  //Close
  fnCloseArea() {
    try {
      this.router.navigate(['/Area']);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  private fnEditMode(deptBO) {


    this.areaform.setValue({
      areaname: deptBO.areaname,
      areaid: deptBO.areaid,
      cityid: deptBO.cityid,
      pincode: deptBO.pincode,
      latitude: deptBO.latitude,
      longitude: deptBO.longitude,
      iconurl: deptBO.iconurl,
      iconimage: null,
      radius: deptBO.radius,
      isdisplay: deptBO.isdisplay,
      rptcompanyname: deptBO.rptcompanyname,
      address1: deptBO.address1,
      address2: deptBO.address2,
      address3: deptBO.address3,
      address4: deptBO.address4,
      address5: deptBO.address5,
      trnno: deptBO.trnno,
      rptheadername: deptBO.rptheadername,
      footer1: deptBO.footer1,
      footer2: deptBO.footer2,
      footer3: deptBO.footer3,
      footer4: deptBO.footer4,
      footer5: deptBO.footer5,
      footer6: deptBO.footer6,
      footer7: deptBO.footer7,
      footer8: deptBO.footer8,
      footer9: deptBO.footer9,
      footer10: deptBO.footer10,
      inactive: deptBO.inactive,
    });

    this.picurl = deptBO.iconurl;


    this.isaddmode = false;
    this.iseditmode = true;
    this.isdeletemode = false;
    this.isviewmode = false;
  }


  //Image Import
  changeListener($event): void {
    try {
      console.log($event.target)
      this.readThis($event.target);
    } catch (ex) {
      this.fnShowErrorMsg(ex);
    }
  }

  readThis(inputValue: any): void {

    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.image = myReader.result;
      this.strImage = this.image.split(',')[1];
    }
    myReader.readAsDataURL(file);
  }

  removeimage() {
    this.image = null;
    this.strImage = null;
    this.picurl = "";
  }


  fnOpenFileBrowser(event: any) {

    event.preventDefault();

    let element: HTMLElement = document.getElementById('image') as HTMLElement;
    element.click();

  }

  checkValue(values: any, index) {
    this.areapaymentColl[index].ischeck = values.currentTarget.checked;
  }

  checkValueDepartment(values: any, index) {
    this.areadepartmentColl[index].ischeck = values.currentTarget.checked;
  }

  fnInsertActivityLog(action, refno, desc) {
    try {
      var data = {
        logtype: action,
        logat: this.formname,
        refnum: refno,
        logdescr: desc,
        logby: this.loginService.fnGetModUserId(),
      }


      this.appService.fnInsertActivity(data).subscribe(
        (res) => {
        },
        (err) => {

        });
    } catch {

    }

  }


  addMarker(lat: number, lng: number) {
    this.markers.push({ lat, lng, alpha: 0.4 });
  }


  private fnShowProgress() {
    this.showProgress = true;
  }
  private fnHideProgress() {
    this.showProgress = false;
  }


  private fnShowErrorMsg(ex) {
    this.toastr.warning('Internal Error', ex);
  }

}
